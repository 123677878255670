<script setup lang="ts">
const { $toast, $api } = useNuxtApp()
const route = useRouter().currentRoute

const isVisible = defineModel('isVisible', {
  type: Boolean,
  default: false,
})
const types = [
  {
    id: 'problem',
    name: 'Проблема',
  },
  {
    id: 'feature',
    name: 'Запрос функции',
  },
  {
    id: 'feedback',
    name: 'Обратная связь',
  },
]
const formData = ref({
  type: 'problem',
  debug_data: {},
  text: '',
  // branch_id: route.value.params.id,
})
const isLoading = ref(false)

function reset() {
  isVisible.value = false
  formData.value.type = 'problem'
  formData.value.text = ''
}

async function send() {
  isLoading.value = true

  try {
    await $api.branchService.createTicket(route.value.params.id, formData.value)
    $toast.success('Получили ваше сообщение. Спасибо!')
    reset()
  }
  catch (err) {
    $toast.error('Возникла ошибка, попробуйте позже')
    console.error(err)
  }
  isLoading.value = false
}

// watch(
//   () => route.value,
//   (val) => {
//     formData.value.branch_id = val.params.id
//   },
// )

// filter all functions and empty objects
function serializeNavigator() {
  const _navigator = {}

  for (const i in window.navigator) {
    if (typeof navigator[i] !== 'object') {
      if (typeof navigator[i] !== 'function') {
        _navigator[i] = navigator[i]
      }
    }
    else if (navigator[i] !== null && navigator[i] !== undefined) {
      if (Object.keys(navigator[i]).length > 0) {
        _navigator[i] = JSON.stringify(navigator[i])
      }
    }
  }

  return _navigator
}

onMounted(() => {
  formData.value.debug_data = {
    env: import.meta.env.MODE,
    ...serializeNavigator(),
  }
})
</script>

<template>
  <RForm
    v-model:is-visible="isVisible"
    title="Вопрос, пожелание, предложение"
    sub-title="Как мы можем улучшить Ранг? Если вы хотите попросить какую-то функцию, расскажите, как вы планируете её использовать, и почему она важна для вас."
  >
    <form class="w-full flex flex-col gap-4" @submit.prevent="send">
      <div class="flex mt-2">
        <div
          v-for="(type, idx) of types"
          :key="type.id"
          :class="[
            formData.type === type.id
              ? 'bg-secondary-100 hover:bg-secondary-300 border-secondary-100'
              : 'border-black border-opacity-20 hover:bg-secondary-100',
            {
              'border-l-[1px]':
                idx === 0
                || (idx === types.length - 1 && formData.type === 'question'),
              'border-r-[1px]':
                idx === types.length - 1
                || (idx === 0 && formData.type === 'question'),
              'border-[1px]': idx !== types.length - 1 && idx !== 0,
              'rounded-l-md': idx === 0,
              'rounded-r-md': idx === types.length - 1,
            },
          ]"
          class="flex justify-center items-center px-5 py-1 border-y-[1px] cursor-pointer duration-150"
          @click="formData.type = type.id"
          v-text="type.name"
        />
      </div>
      <textarea
        v-model="formData.text"
        required
        minlength="10"
        cols="20"
        rows="5"
        placeholder="Что если бы..."
        class="w-full show bg-[#F5F5F5] border border-black border-opacity-0 focus:border-opacity-70 rounded-md py-3 px-5 placeholder:pl-0.5 outline-none duration-150"
      />
      <RButton type="submit" class="w-fit" :is-loading="isLoading">
        Отправить
      </RButton>
    </form>
  </RForm>
</template>
