<script setup lang="ts">
import { useTariff } from '~/store/tariff'

const tariff = useTariff()
</script>

<template>
  <div v-if="tariff.checkFeature('freemium')" class="absolute top-10 left-0 py-3 w-full text-center bg-yellow-400 text-btn-text show">
    Это бесплатная версия с ограниченным функционалом, <span class="underline underline-offset-4 cursor-pointer" @click="tariff.show()">в полной версии</span> ограничения будут сняты
  </div>
</template>

<style>
@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.show {
  opacity: 0;
  animation: show 200ms ease-in-out;
  animation-fill-mode: forwards;
}
</style>
