<script setup lang="ts">
import RequestLimits from '~/components/branch/RequestLimits.client.vue'
import RButton from '~/components/rButton.vue'

import { useBranchStore } from '~/store/branches'
import { useTariff } from '~/store/tariff'

const {
  $api: { branchService },
} = useNuxtApp()
const route = useRouter().currentRoute
const store = useBranchStore()
const { branch } = storeToRefs(store)
const tariff = useTariff()
const { data } = await branchService.getBranchRequests(route.value.params.id)
const { isLoading } = storeToRefs(store)
const showFeedbackForm = ref(false)

const links = computed(() => [
  {
    name: 'Аналитика',
    icon: 'uil:analytics',
    route: 'analytics',
    disabled: false,
  },
  {
    name: 'Запросы отзывов',
    icon: 'uil:user-plus',
    route: 'requests',
    disabled: data.length > 0 ? false : branch.value.freemium,
  },
  { name: 'Отзывы', icon: 'uil:star', route: 'reviews', disabled: false },
  {
    name: 'Сотрудники',
    icon: 'uil:users-alt',
    route: 'staff',
    disabled: tariff.checkFeature('freemium'),
  },
  { name: 'Настройки', icon: 'uil:cog', route: 'settings', disabled: false },
])

function redirect() {
  navigateTo(
    `/branches/${route.value.params.id}/add/${branch.value.slug}/${branch.value.hash}`,
  )
}
</script>

<template>
  <div class="bg-milky-100 min-h-full min-w-[200px] w-[200px]">
    <FeedbackForm v-model:is-visible="showFeedbackForm" />

    <div class="show">
      <ul class="flex flex-col gap-4">
        <template v-if="isLoading">
          <RSkeleton v-for="i in Array.from({ length: 5 }).map((el, idx) => el = idx)" :key="i" bg-class="bg-blue-200" class="w-40 mx-4 h-[18px]" />
        </template>
        <li
          v-for="link in links"
          v-else
          :key="link.name"
          class="text-blue-500 hover:text-blue-600 text-primary-small"
          :class="[link.disabled ? 'cursor-not-allowed' : 'cursor-pointer']"
        >
          <NuxtLink
            no-prefetch
            active-class="text-orange-500 hover:text-orange-500"
            class="ml-4 flex flex-nowrap flex-row gap-3 items-center text-[14px] duration-150"
            :class="[
              link.disabled ? 'opacity-50 text-black pointer-events-none' : '',
            ]"
            :to="`/branches/${route.params.id}/${link.route}`"
          >
            <svg
              v-if="link.disabled"
              xmlns="http://www.w3.org/2000/svg"
              width="1.3em"
              height="1.3em"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8.66667 9.66667C8.66667 10.0349 8.36819 11.3333 8 11.3333C7.63181 11.3333 7.33333 10.0349 7.33333 9.66667C7.33333 9.29848 7.63181 9 8 9C8.36819 9 8.66667 9.29848 8.66667 9.66667Z"
                fill="#9A9A9A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 6C13.1046 6 14 6.89543 14 8V12.6641C14 13.7686 13.1046 14.6641 12 14.6641H4C2.89543 14.6641 2 13.7686 2 12.6641V8C2 6.89543 2.89543 6 4 6H12ZM4 7.33333H12C12.3682 7.33333 12.6667 7.63181 12.6667 8V12.6641C12.6667 13.0323 12.3682 13.3307 12 13.3307H4C3.63181 13.3307 3.33333 13.0323 3.33333 12.6641V8C3.33333 7.63181 3.63181 7.33333 4 7.33333Z"
                fill="#9A9A9A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 1.33304C11.1046 1.33304 12 2.22847 12 3.33304V5.33333V7.33333H4V5.33333V3.33304C4 2.22847 4.89543 1.33304 6 1.33304H10ZM6 2.66637H10C10.3682 2.66637 10.6667 2.96485 10.6667 3.33304V5.33333V6H5.33333V5.33333V3.33304C5.33333 2.96485 5.63181 2.66637 6 2.66637Z"
                fill="#9A9A9A"
              />
            </svg>
            <Icon v-else :name="`${link.icon}`" width="1.3em" height="1.3em" />
            <span>{{ link.name }}</span>
          </NuxtLink>
        </li>
        <div
          class="ml-4 mt-5 flex flex-col gap-2 p-0 justify-center items-start show"
        >
          <RSkeleton v-if="isLoading" bg-class="bg-neutral-300" class="w-40 h-60" />
          <template v-else>
            <RButton
              v-if="!tariff.checkFeature('freemium')"
              font-styles="text-m-primary-small"
              class="p-0"
              @click="redirect"
            >
              Запросить отзыв
            </RButton>
            <RButton
              v-else
              font-styles="text-primary-small text-left max-w-40 font-bold leading-5"
              class="p-0"
              @click="tariff.show()"
            >
              Перейти<br>на полную версию
            </RButton>

            <p v-if="tariff.checkFeature('freemium')" class="p-0 max-w-40 text-primary-small">
              Полная версия позволяет получать в&nbsp;10&nbsp;раз больше положительных отзывов, перехватывать негативные, быстро расти в&nbsp;рейтингах и&nbsp;обгонять конкурентов в&nbsp;доле трафика клиентов из&nbsp;карт.
            </p>

            <template v-else>
              <ClientOnly>
                <RequestLimits type="aside" />
              </ClientOnly>
            </template>

            <div class="bg-white mt-5 max-w-44">
              <div class="flex flex-col gap-2 p-2 border-t-[1px] border-x-[1px] border-secondary-300 rounded-t-md pb-4">
                <img
                  class="h-4 w-4 select-none"
                  src="@/assets/icons/info.svg"
                  alt="info"
                >
                <p class="text-primary-small text-secondary-500">
                  Расскажите, как ещё Ранг может помочь решить вашу задачу.<br>А мы сделаем.
                </p>
              </div>
              <button
                class="w-full flex gap-1 justify-center py-3 rounded-b-md text-primary-small text-blue-500 hover:text-blue-400 border-[1px] border-secondary-300 duration-150"
                @click="showFeedbackForm = true"
              >
                🎉 Запросить функцию
              </button>
            </div>
          </template>
        </div>
      </ul>
    </div>
  </div>
</template>

<style scoped>
@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.show {
  opacity: 0;
  animation: show 200ms ease-in-out;
  animation-fill-mode: forwards;
}
</style>
